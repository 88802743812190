import {
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  SubMenu,
  Tag,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui"
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query"
import api from "api"
import {
  ActiveJobApplicantStepStatus,
  ActiveJobApplicantStepType,
} from "api/resources/jobs/step.types"
import {
  ActiveJobApplicant,
  Job,
  JobApplicantStatus,
} from "api/resources/jobs/types"
import VerifiedBadge from "assets/home/verifiedBadge.svg"
import clsx from "clsx"
import AddAssessmentDialog from "components/teacher/Dialogs/AddAssessmentDialog"
import AddFeedbackDialog from "components/teacher/Dialogs/AddFeedbackDialog"
import AddSalaryDialog from "components/teacher/Dialogs/AddSalaryDialog"
import EditAssessmentDueDate from "components/teacher/Dialogs/EditAssessmentDueDate"
import EditInterviewDialog from "components/teacher/Dialogs/EditInterviewDialog"
import EditLiveDemoDialog from "components/teacher/Dialogs/EditLiveDemoDialog"
import MarkAsCompleteDialog from "components/teacher/Dialogs/MarkAsCompleteDialog"
import RejectApplicationDialog from "components/teacher/Dialogs/RejectApplicationDialog"
import UpdateOfferStatusDialog from "components/teacher/Dialogs/UpdateOfferStatusDialog"
import { format } from "date-fns"
import {
  ArrowDown,
  ArrowUp,
  MoreVert,
  QuestionMark,
  WarningCircle,
} from "iconoir-react"
import capitalize from "lodash/capitalize"
import { useState } from "react"
import { createUseStyles } from "react-jss"
import { Link, useNavigate } from "react-router-dom"
import {
  generateHuddleURL,
  getTeacherProfileRoute,
  getTeacherTimelineRoute,
} from "utils/helpers"
import usePaginationScroll from "utils/hooks/usePaginationScroll"
import { routes } from "utils/routes"
import { useSchoolActions } from "views/auth/useSchoolActions"
import { Actions } from "views/auth/useUserActions"
import useUserType from "views/auth/useUserType"

const useStyles = createUseStyles(theme => ({
  root: {
    [theme.breakpoints.down("md")]: {
      overflowX: "auto",
    },
  },
  container: {
    background: "white",
    borderRadius: "4px",
    border: `1px solid ${theme.colors.surface[200]}`,
    minWidth: "800px",
  },

  tableGrid: {
    display: "grid",
    gridTemplateColumns: "70px 1.5fr 1fr 1fr 1.5fr 50px",
  },

  tableItem: {
    borderTop: `1px solid ${theme.colors.surface[200]}`,
    position: "relative",
  },
  error: {
    borderLeft: `3px solid ${theme.colors.warning[400]}`,
  },

  image: {
    width: "32px",
    height: "32px",
  },

  textSmall: {
    fontSize: "12px",
    lineHeight: "15.6px",
  },

  warningIcon: {
    position: "absolute",
    left: "-40px",
    height: "100%",
    color: theme.colors.warning[500],
  },

  noApplicantsDiv: {
    background: "#f8fafc",
    border: `1px solid ${theme.colors.onSurface[200]}`,
    borderRadius: theme.spacing(0.5),
    paddingLeft: theme.spacing(9),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

type Props = {
  job: Pick<Job, "position" | "id" | "dateReopened" | "status">
  isClosed?: boolean
  isPreviousApplications?: boolean
  schoolId: string
  canManageHiringProcess: boolean
}

export const getTag = (step: ActiveJobApplicantStepStatus) => {
  switch (step) {
    case ActiveJobApplicantStepStatus.NOT_STARTED: {
      return <Tag color="warning" label="Not Started" />
    }
    case ActiveJobApplicantStepStatus.IN_PROGRESS:
      return <Tag color="primary" label="In Progress" />
    case ActiveJobApplicantStepStatus.COMPLETED:
      return <Tag color="success" label="Completed" />
    case ActiveJobApplicantStepStatus.SKIPPED:
      return <Tag color="primary" label="Skipped" />
    case ActiveJobApplicantStepStatus.CANCELLED:
      return <Tag color="critical" label="Cancelled" />
    default:
      break
  }
}

const getStepName = ({ currentStep: step, jobOffer }: ActiveJobApplicant) => {
  if (jobOffer) {
    return "Offer letter sent"
  }

  if (!step) return "-"

  switch (step.stepType) {
    case ActiveJobApplicantStepType.INTERVIEW:
      return `Telephonic Interview - ${step.step.name}`

    case ActiveJobApplicantStepType.LIVE_DEMO:
      return `Video Interview ${
        step.step.isTelephonic ? "(Online)" : "(Offline)"
      } - ${step.step.name}`

    case ActiveJobApplicantStepType.ASSESSMENT:
      return `Assessment - ${step.step.name}`
    default:
      return "-"
  }
}

const getStatus = ({ status }: ActiveJobApplicant) => {
  switch (status) {
    case JobApplicantStatus.CANCELLED:
    case JobApplicantStatus.IN_PROGRESS:
    case JobApplicantStatus.PENDING:
    case JobApplicantStatus.REJECTED:
    case JobApplicantStatus.WITHDRAWN:
      return capitalize(status)
    case JobApplicantStatus.JOB_OFFER_ACCEPTED:
    case JobApplicantStatus.JOB_OFFER_REJECTED:
    case JobApplicantStatus.JOB_OFFER_SENT:
      return `Offer ${capitalize(status.split("_")[2])}`
    default:
      return "-"
  }
}
// const canMarkAsComplete = (item: ActiveJobApplicant) => {
//   // No point of this button if there is no step
//   if (!item.currentStep) return false

//   // Cannot mark assessment as complete
//   if (item.currentStep.stepType === ActiveJobApplicantStepType.ASSESSMENT) return false

//   return item.currentStep.status !== ActiveJobApplicantStepStatus.COMPLETED
// }

const ActiveApplicationsTab = ({
  job,
  isClosed,
  isPreviousApplications,
  schoolId,
  canManageHiringProcess,
}: Props) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const { hasSchoolAction } = useSchoolActions({
    schoolIds: [schoolId],
  })

  const hasPermissionToSendOffer = hasSchoolAction(
    Actions.sendJobOffer,
    schoolId
  )
  const canUpdateOfferStatus = hasSchoolAction(
    Actions.updateOfferStatus,
    schoolId
  )

  const canMarkAsPlaced = hasSchoolAction(Actions.canMarkAsPlaced, schoolId)
  /**
   * isCreating is used to differentiate,
   * whether the dialog was opened for add or edit
   * for interview and live demo
   */
  const [isCreating, setIsCreating] = useState(false)

  const [openEditAssessmentDateDialog, setOpenEditAssessmentDateDialog] =
    useState<ActiveJobApplicant | null>(null)
  const [openInterviewDialog, setOpenInterviewDialog] =
    useState<ActiveJobApplicant | null>(null)
  const [timeOrderingRecent, setTimeOrderingRecent] = useState(true)

  const [openAddSalaryDialog, setOpenAddSalaryDialog] = useState<{
    user: ActiveJobApplicant
  } | null>(null)

  const [openOfferStatusDialog, setOpenOfferStatusDialog] = useState<{
    user: ActiveJobApplicant
    type: JobApplicantStatus
  } | null>(null)

  const [openLiveDemo, setOpenLiveDemoDialog] =
    useState<ActiveJobApplicant | null>(null)
  const [openAssessment, setOpenAssessmentDialog] =
    useState<ActiveJobApplicant | null>(null)
  const [openAddFeedback, setOpenAddFeedback] =
    useState<ActiveJobApplicant | null>(null)
  const [openRejectApplicant, setOpenRejectApplicantDialog] =
    useState<ActiveJobApplicant | null>(null)
  const [openMarkAsComplete, setOpenMarkAsComplete] =
    useState<ActiveJobApplicant | null>(null)

  const queryKey = isPreviousApplications
    ? "previousApplications"
    : "activeApplications"

  const orderingKey = isPreviousApplications
    ? "date_created"
    : "current_step__due_date"

  const {
    isFetching: loading,
    hasNextPage,
    fetchNextPage,
    data,
    refetch,
  } = useInfiniteQuery({
    queryKey: [queryKey, timeOrderingRecent, job.id, schoolId],
    queryFn: x =>
      api.jobs.applicants.listActive({
        urlParams: {
          jobId: job.id,
        },
        params: {
          page: x.pageParam,
          ordering: timeOrderingRecent ? orderingKey : `-${orderingKey}`,
          ...(isPreviousApplications
            ? {
                status: "previous",
              }
            : {}),
        },
        headers: {
          "School-Id": schoolId,
        },
      }),
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      return lastPage.isSuccessful ? lastPage.data.nextPage : undefined
    },
  })
  const applicants =
    data?.pages.map(page => (page.isSuccessful ? page.data.data : [])).flat() ||
    []

  const listApplicants = () => refetch()

  const { ref } = usePaginationScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    actionFunc: fetchNextPage,
  })

  // const renderAddStepButton = ({ currentStep }: ActiveJobApplicant) => {
  //   if (currentStep === null) return true

  //   if (
  //     currentStep.stepType === ActiveJobApplicantStepType.INTERVIEW &&
  //     currentStep.step.url &&
  //     currentStep.status !== ActiveJobApplicantStepStatus.IN_PROGRESS
  //   ) {
  //     return true
  //   }

  //   if (
  //     currentStep.stepType === ActiveJobApplicantStepType.LIVE_DEMO &&
  //     currentStep.step.meetingUrl
  //   ) {
  //     return true
  //   }

  //   return currentStep.status !== ActiveJobApplicantStepStatus.COMPLETED
  // }

  const AddAnotherStep = ({ item }: { item: ActiveJobApplicant }) => (
    <Menu
      menuButton={
        <Button size="sm" variant="text">
          Add another step
        </Button>
      }
    >
      <MenuItem
        onClick={() => {
          setOpenInterviewDialog(item)
          setIsCreating(true)
        }}
      >
        Telephonic Interview
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenLiveDemoDialog(item)
          setIsCreating(true)
        }}
      >
        Video Interview
      </MenuItem>
      {/* <MenuItem
        onClick={() => {
          setOpenAssessmentDialog(item)
          setIsCreating(true)
        }}
      >
        Assessment
      </MenuItem> */}
    </Menu>
  )

  const InterviewActions = ({ item }: { item: ActiveJobApplicant }) => {
    if (item.currentStep?.stepType !== ActiveJobApplicantStepType.INTERVIEW)
      return null

    if (
      item.currentStep.step.url &&
      item.currentStep.status !== ActiveJobApplicantStepStatus.IN_PROGRESS
    )
      return (
        <Button
          component={Link}
          rel="opener"
          target="_blank"
          to={generateHuddleURL({
            meetingUrl: item.currentStep.step.url,
            interviewId: item.currentStep.step.id,
          })}
          variant="text"
        >
          Join Telephonic Interview
        </Button>
      )

    switch (item.currentStep.status) {
      case ActiveJobApplicantStepStatus.COMPLETED:
        return <AddAnotherStep item={item} />
      case ActiveJobApplicantStepStatus.NOT_STARTED:
        return (
          <Button
            size="sm"
            variant="text"
            onClick={() => setOpenInterviewDialog(item)}
          >
            Reschedule Telephonic Interview
          </Button>
        )
      case ActiveJobApplicantStepStatus.IN_PROGRESS:
        return (
          <>
            {item.currentStep.step.url && (
              <Button
                component={Link}
                rel="opener"
                target="_blank"
                to={generateHuddleURL({
                  meetingUrl: item.currentStep.step.url,
                  interviewId: item.currentStep.step.id,
                })}
                variant="text"
              >
                Join Telephonic Interview
              </Button>
            )}
            <Button
              color="success"
              variant="text"
              onClick={() => setOpenMarkAsComplete(item)}
            >
              Mark As Complete
            </Button>
          </>
        )

      default:
        return null
    }
  }

  const LiveDemoActions = ({ item }: { item: ActiveJobApplicant }) => {
    if (item.currentStep?.stepType !== ActiveJobApplicantStepType.LIVE_DEMO)
      return null

    if (item.currentStep.step.videoMeetingId)
      return (
        <Button
          component="a"
          href={item.currentStep.step.videoMeetingId}
          target="_blank"
          variant="text"
        >
          Join Video Interview
        </Button>
      )

    switch (item.currentStep.status) {
      case ActiveJobApplicantStepStatus.COMPLETED:
        return <AddAnotherStep item={item} />
      case ActiveJobApplicantStepStatus.NOT_STARTED:
        return (
          <Button
            size="sm"
            variant="text"
            onClick={() => setOpenLiveDemoDialog(item)}
          >
            Reschedule Video Interview
          </Button>
        )
      case ActiveJobApplicantStepStatus.IN_PROGRESS:
        return (
          <Button
            color="success"
            variant="text"
            onClick={() => setOpenMarkAsComplete(item)}
          >
            Mark As Complete
          </Button>
        )

      default:
        return null
    }
  }

  const AssessmentActions = ({ item }: { item: ActiveJobApplicant }) => {
    switch (item.currentStep?.status) {
      case ActiveJobApplicantStepStatus.COMPLETED:
        return <AddAnotherStep item={item} />
      case ActiveJobApplicantStepStatus.NOT_STARTED:
        return (
          <Button
            size="sm"
            variant="text"
            onClick={() => setOpenEditAssessmentDateDialog(item)}
          >
            Change Deadline
          </Button>
        )
      // case ActiveJobApplicantStepStatus.IN_PROGRESS:
      //   return (
      //     <>
      //       <Button
      //         color="success"
      //         variant="text"
      //         onClick={() => setOpenMarkAsComplete(item)}
      //       >
      //         Mark As Complete
      //       </Button>
      //     </>
      //   )

      default:
        return null
    }
  }

  const actions = (item: ActiveJobApplicant) => {
    if (!canManageHiringProcess) {
      return null
    }
    if (item.jobOffer)
      return (
        <Button
          component={Link}
          nudge="left"
          to={routes.school.viewOffer
            .replace(":schoolId", schoolId)
            .replace(":username", item.user.username)
            .replace(":jobOfferId", String(item.jobOffer.id))}
          variant="text"
        >
          View Job Offer
        </Button>
      )

    if (item.status === "job_offer_sent") return <Tag label="Job Offer Sent" />

    if (item.currentStep === null) {
      return (
        // <Button
        //   nudge="left"
        //   variant="text"
        //   onClick={() => setOpenInterviewDialog(item)}
        // >
        //   Set up Telephonic Interview
        // </Button>
        <AddAnotherStep item={item} />
      )
    }

    switch (item.currentStep.stepType) {
      case ActiveJobApplicantStepType.ASSESSMENT:
        return <AssessmentActions item={item} />
      case ActiveJobApplicantStepType.INTERVIEW:
        return <InterviewActions item={item} />
      case ActiveJobApplicantStepType.LIVE_DEMO:
        return <LiveDemoActions item={item} />
      default:
        return null
    }
  }

  const queryClient = useQueryClient()

  const removeApplicantFromList = () => {
    refetch()
    queryClient.invalidateQueries({
      queryKey: api.jobs.overviewCount.key(job.id.toString()),
    })
  }

  // if (applicants.data.length === 0)
  //   return (
  //     <NoDataCard
  //       className="mt-3"
  //       message="There are no active applications. Head to the 'Applications' tab to check out teachers who are waiting to be a part of this hiring process."
  //     />
  //   )

  return (
    <>
      {loading && (
        <div className="mt-5 flex justify-center">
          <CircularProgress />
        </div>
      )}
      <div className={clsx(classes.root, "my-3")}>
        <div className={classes.container}>
          <div className={clsx(classes.tableGrid, "py-2")}>
            <div />

            <Typography variant="strongSmallBody">Name</Typography>

            <div className="flex items-center gap-0.5">
              <Typography variant="strongSmallBody">
                {isClosed ? "Status" : "Current Step"}
              </Typography>
              <Tooltip
                offsetY={10}
                title="Candidate's current stage in your hiring process"
              >
                <QuestionMark
                  color="#64748B"
                  style={{ height: "20px", width: "20px" }}
                />
              </Tooltip>
            </div>
            <div className="flex items-center gap-0.25">
              <Typography variant="strongSmallBody">Time</Typography>
              <IconButton onClick={() => setTimeOrderingRecent(v => !v)}>
                {timeOrderingRecent ? (
                  <ArrowDown className="size-2 text-onSurface-500" />
                ) : (
                  <ArrowUp className="size-2 text-onSurface-500" />
                )}
              </IconButton>
            </div>
          </div>

          {applicants.length > 0 ? (
            <>
              {applicants.map(item => {
                const tag =
                  !item.currentStep || item.jobOffer?.id
                    ? null
                    : getTag(item.currentStep.status)

                const interviewNotScheduled =
                  item.currentStep === null && !item.jobOffer?.id

                const disableFurtherActions =
                  !canManageHiringProcess ||
                  Boolean(item.jobOffer?.id) ||
                  item.status === JobApplicantStatus.JOB_OFFER_SENT ||
                  item.status === JobApplicantStatus.JOB_OFFER_REJECTED ||
                  item.status === JobApplicantStatus.JOB_OFFER_ACCEPTED

                return (
                  <div
                    ref={ref}
                    className={clsx(
                      classes.tableGrid,
                      classes.tableItem,
                      { [classes.error]: interviewNotScheduled },
                      "py-2"
                    )}
                    key={item.id}
                  >
                    <div className="pl-3">
                      <Avatar
                        name={item.user.fullName}
                        size="xs"
                        src={item.user.profile.picture ?? undefined}
                      />
                    </div>
                    <div>
                      <div className="flex items-center gap-0.5">
                        <Button
                          className="-ml-0.75"
                          component={Link}
                          to={getTeacherProfileRoute({
                            jobId: job.id,
                            username: item.user.username,
                            schoolId,
                          })}
                          variant="link"
                        >
                          {item.user.fullName}
                        </Button>
                        {item.user.profile.isVerified && (
                          <img src={VerifiedBadge} alt="" />
                        )}
                      </div>

                      <div className="flex items-center">
                        <Typography
                          className={classes.textSmall}
                          color="onSurface.500"
                        >
                          {item.user.email}
                        </Typography>
                        {/* <button
                    onClick={() =>
                      window.open(`mailto:${item.personal.email}`, "_blank")
                    }
                  >
                    <OpenInWindow />
                  </button> */}
                      </div>

                      {item.user.profile.phoneNumber && (
                        <Typography
                          className={classes.textSmall}
                          color="onSurface.500"
                        >
                          +{item.user.profile.phoneNumber.code}
                          {item.user.profile.phoneNumber.number}
                        </Typography>
                      )}
                    </div>

                    <div className="flex flex-col justify-center">
                      <Typography className="mb-0.5" variant="smallBody">
                        {isPreviousApplications
                          ? getStatus(item)
                          : getStepName(item)}
                      </Typography>
                      {tag}
                    </div>

                    <div className="flex flex-col justify-center">
                      {item.currentStep?.dueDate ? (
                        <>
                          <Typography variant="smallBody">
                            <b>
                              {format(
                                new Date(item.currentStep.dueDate),
                                "d MMM yyyy"
                              )}
                            </b>
                            <br />
                            {format(
                              new Date(item.currentStep.dueDate),
                              "hh:mm aa"
                            )}
                          </Typography>
                        </>
                      ) : isPreviousApplications ? (
                        <>
                          <Typography variant="smallBody">
                            <b>
                              {format(new Date(item.dateCreated), "d MMM yyyy")}
                            </b>
                            <br />
                            {format(new Date(item.dateCreated), "hh:mm aa")}
                          </Typography>
                        </>
                      ) : (
                        <Typography variant="smallBody">-</Typography>
                      )}
                    </div>

                    <div className="flex flex-col items-end justify-center">
                      {actions(item)}
                    </div>

                    {(isClosed !== true ||
                      item.status === JobApplicantStatus.JOB_OFFER_SENT) && (
                      <div className="mr-1.25 flex items-center justify-end">
                        <Menu
                          menuButton={
                            <IconButton>
                              <MoreVert />
                            </IconButton>
                          }
                          portal
                        >
                          <MenuItem>
                            <Link
                              to={getTeacherProfileRoute({
                                jobId: job.id,
                                username: item.user.username,
                                schoolId,
                              })}
                            >
                              View Profile
                            </Link>
                          </MenuItem>
                          <MenuItem>
                            <Link
                              to={getTeacherTimelineRoute({
                                jobId: job.id,
                                jobApplicantId: item.id.toString(),
                                username: item.user.username,
                                schoolId: schoolId,
                              })}
                            >
                              View Timeline
                            </Link>
                          </MenuItem>
                          {canManageHiringProcess && (
                            <>
                              {item.status ===
                                JobApplicantStatus.JOB_OFFER_SENT &&
                                item.jobOffer === null &&
                                canMarkAsPlaced && (
                                  <MenuItem
                                    onClick={() =>
                                      setOpenAddSalaryDialog({
                                        user: item,
                                      })
                                    }
                                  >
                                    Add Salary
                                  </MenuItem>
                                )}
                              {(hasPermissionToSendOffer ||
                                canUpdateOfferStatus) && (
                                <SubMenu label="Hired">
                                  {hasPermissionToSendOffer &&
                                    item.status ===
                                      JobApplicantStatus.IN_PROGRESS && (
                                      <MenuItem
                                        disabled={disableFurtherActions}
                                        onClick={() => {
                                          navigate(
                                            `${routes.school.sendOffer
                                              .replace(":schoolId", schoolId)
                                              .replace(
                                                ":jobApplicantId",
                                                String(item.id)
                                              )
                                              .replace(
                                                ":username",
                                                item.user.username
                                              )}&jobId=${job.id}`
                                          )
                                        }}
                                      >
                                        Send Offer
                                      </MenuItem>
                                    )}
                                  {canUpdateOfferStatus &&
                                    item.status ===
                                      JobApplicantStatus.IN_PROGRESS && (
                                      <MenuItem
                                        onClick={() =>
                                          setOpenOfferStatusDialog({
                                            type: JobApplicantStatus.JOB_OFFER_SENT,
                                            user: item,
                                          })
                                        }
                                      >
                                        Job Offer Sent
                                      </MenuItem>
                                    )}

                                  {canUpdateOfferStatus && !item.jobOffer && (
                                    <>
                                      <MenuItem
                                        className="!text-success-500 hover:!bg-success-100"
                                        onClick={() =>
                                          setOpenOfferStatusDialog({
                                            type: JobApplicantStatus.JOB_OFFER_ACCEPTED,
                                            user: item,
                                          })
                                        }
                                      >
                                        Job Offer Accepted
                                      </MenuItem>
                                      <MenuItem
                                        color="critical"
                                        onClick={() =>
                                          setOpenOfferStatusDialog({
                                            type: JobApplicantStatus.JOB_OFFER_REJECTED,
                                            user: item,
                                          })
                                        }
                                      >
                                        Job Offer Rejected
                                      </MenuItem>
                                    </>
                                  )}
                                </SubMenu>
                              )}

                              <MenuItem
                                color="critical"
                                disabled={disableFurtherActions}
                                onClick={() =>
                                  setOpenRejectApplicantDialog(item)
                                }
                              >
                                Reject
                              </MenuItem>
                            </>
                          )}
                        </Menu>
                      </div>
                    )}

                    {interviewNotScheduled && (
                      <Tooltip
                        offsetY={-20}
                        title="Telephonic Interview not scheduled yet"
                      >
                        <WarningCircle className={classes.warningIcon} />
                      </Tooltip>
                    )}
                  </div>
                )
              })}
            </>
          ) : (
            <div>
              <Typography
                className={classes.noApplicantsDiv}
                color="onSurface.500"
                variant="smallBody"
              >
                No Active Applications
              </Typography>
            </div>
          )}
        </div>
      </div>

      {openEditAssessmentDateDialog?.currentStep && (
        <EditAssessmentDueDate
          afterEdit={() => listApplicants()}
          data={{
            dueDate: openEditAssessmentDateDialog.currentStep.dueDate,
            id: openEditAssessmentDateDialog.currentStep.step.id,
            jobApplicantStepId: openEditAssessmentDateDialog.currentStep.id,
            schoolId,
          }}
          open={Boolean(openEditAssessmentDateDialog)}
          toggle={() => setOpenEditAssessmentDateDialog(null)}
          isEditing
        />
      )}

      {openAssessment && (
        <AddAssessmentDialog
          afterSubmit={() => listApplicants()}
          jobApplicant={openAssessment.id}
          open={Boolean(openAssessment)}
          toggle={() => {
            setOpenAssessmentDialog(null)
            setIsCreating(false)
          }}
          schoolId={schoolId}
        />
      )}

      {openLiveDemo && (
        <EditLiveDemoDialog
          afterSubmit={() => listApplicants()}
          schoolId={schoolId}
          // @ts-expect-error Type narrowing needed
          data={isCreating ? undefined : openLiveDemo.currentStep}
          jobApplicant={openLiveDemo.id}
          open={Boolean(openLiveDemo)}
          toggle={() => {
            setOpenLiveDemoDialog(null)
            setIsCreating(false)
          }}
        />
      )}

      {openInterviewDialog && (
        <EditInterviewDialog
          afterSubmit={() => listApplicants()}
          // @ts-expect-error Type narrowing needed
          data={isCreating ? undefined : openInterviewDialog.currentStep}
          jobApplicant={openInterviewDialog.id}
          open={Boolean(openInterviewDialog)}
          toggle={() => {
            setOpenInterviewDialog(null)
            setIsCreating(false)
          }}
          schoolId={schoolId}
        />
      )}

      {openRejectApplicant && (
        <RejectApplicationDialog
          afterSubmit={removeApplicantFromList}
          resourceId={openRejectApplicant.id}
          jobPosition={job.position}
          mode="activeApplication"
          schoolId={schoolId}
          open={Boolean(openRejectApplicant)}
          toggle={() => setOpenRejectApplicantDialog(null)}
          userName={openRejectApplicant.user.fullName}
        />
      )}

      {openMarkAsComplete?.currentStep && (
        <MarkAsCompleteDialog
          data={{
            id: openMarkAsComplete.currentStep.id,
            name: getStepName(openMarkAsComplete),
            schoolId,
            afterSubmit: () => {
              listApplicants()
              setOpenAddFeedback(openMarkAsComplete)
            },
          }}
          open={Boolean(openMarkAsComplete)}
          toggle={() => setOpenMarkAsComplete(null)}
        />
      )}

      {openAddFeedback?.currentStep && (
        <AddFeedbackDialog
          id={openAddFeedback.currentStep.step.id}
          schoolId={schoolId}
          open={Boolean(openAddFeedback)}
          toggle={() => setOpenAddFeedback(null)}
          type={openAddFeedback.currentStep?.stepType}
        />
      )}
      {openOfferStatusDialog && (
        <UpdateOfferStatusDialog
          toggle={() => {
            listApplicants()
            setOpenOfferStatusDialog(null)
          }}
          open={Boolean(openOfferStatusDialog)}
          data={{
            id: openOfferStatusDialog.user.id,
            name: openOfferStatusDialog.user.user.fullName,
            schoolId: schoolId,
            afterSubmit: () => {
              listApplicants()
              setOpenOfferStatusDialog(null)
            },
            status: openOfferStatusDialog.type,
          }}
        />
      )}
      {openAddSalaryDialog && (
        <AddSalaryDialog
          toggle={() => {
            listApplicants()
            setOpenAddSalaryDialog(null)
          }}
          open={Boolean(openAddSalaryDialog)}
          data={{
            id: openAddSalaryDialog.user.id,
            schoolId,
            afterSubmit: () => {
              listApplicants()
              setOpenAddSalaryDialog(null)
            },
          }}
        />
      )}
    </>
  )
}
export default ActiveApplicationsTab
