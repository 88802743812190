import { Maintenance } from "@suraasa/error-pages"
import { theme } from "@suraasa/placebo-ui"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import GetActions from "components/auth/GetActions"
import ErrorPage from "components/ErrorPage"
import GlobalState from "components/GlobalState"
import Help from "components/help/Help"
import OfferLetterSent from "components/teacher/OfferLetterSent"
import { PostHogProvider } from "posthog-js/react"
import { ThemeProvider } from "react-jss"
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom"
import { db } from "services/firebase"
import { getAuthInfo } from "utils/auth"
import { isLocalhost, USER_TYPE } from "utils/constants"
import { routes } from "utils/routes"
import ToastProvider from "utils/toast/ToastProvider"
// import AssessmentForm from "views/assessments/Create"
// import AssessmentResult from "views/assessments/Result"
import Settings from "views/auth/Settings"
import SSO from "views/auth/SSO"
import Explore from "views/Explore"
import Home from "views/Home"
import JobClosed from "views/jobs/Closed"
import JobCreate from "views/jobs/CreateTab"
import JobDetails from "views/jobs/Details"
import Profile from "views/Profile"
import EditProfile from "views/Profile/Edit"
import SkillProfile from "views/Profile/SkillProfile"
import SchoolHome from "views/School"
import SchoolRoot from "views/School/Root"
import Interview from "views/teacher/Interview"
import TeacherProfile from "views/teacher/Profile"
import TeacherTimeline from "views/teacher/TeacherTimeline"
import ViewOffer from "views/teacher/ViewOffer"

const PrivateRoute = () => {
  const auth = getAuthInfo()
  return auth ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: routes.login,
        search: `?next=${window.location.pathname}`,
      }}
      replace
    />
  )
}

const AuthRoutes = () => {
  const auth = getAuthInfo()
  return auth ? <Navigate to={routes.home} /> : <Outlet />
}

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        /**
         * We don't want failed requests to be retried by default
         * We can enable this on a per-API basis
         */
        retry: false,
      },
    },
  })

  return (
    <PostHogProvider
      apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
      options={{
        api_host: isLocalhost
          ? "https://us.i.posthog.com"
          : `${window.location.origin}/ingest`,
        autocapture: {
          dom_event_allowlist: ["click"], // DOM events from this list ['click', 'change', 'submit']
          element_allowlist: ["button", "a"], // DOM elements from this list ['a', 'button', 'form', 'input', 'select', 'textarea', 'label']
          css_selector_allowlist: ["[data-ph-autocapture]"], // List of CSS selectors
        },
        person_profiles: "identified_only",
        capture_pageview: false,
        capture_pageleave: false,
      }}
    >
      <Router>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <Maintenance firebaseDb={db} platform={USER_TYPE}>
              <ToastProvider domRoot={document.body}>
                <GlobalState>
                  <Routes>
                    {/* Auth */}
                    <Route element={<AuthRoutes />}>
                      <Route element={<SSO />} path={routes.login} />
                    </Route>

                    <Route element={<Help />} path={routes.help} />

                    <Route element={<GetActions />} path="/">
                      <Route element={<Explore />} path={routes.explore} />
                      <Route
                        element={<TeacherProfile />}
                        path={routes.teacherProfile}
                      />

                      <Route element={<PrivateRoute />} path="/">
                        {/* Home */}

                        <Route element={<Home />} path={routes.home} />

                        <Route
                          element={<SkillProfile />}
                          path={routes.publicSkillProfile.root}
                        >
                          <Route
                            element={<SkillProfile />}
                            path={routes.publicSkillProfile.overview}
                          />
                          <Route
                            element={<SkillProfile />}
                            path={routes.publicSkillProfile.evidences}
                          />
                          <Route
                            element={<SkillProfile />}
                            path={routes.publicSkillProfile.evidenceDetails}
                          />
                          <Route
                            element={<SkillProfile />}
                            path={routes.publicSkillProfile.verificationProcess}
                          />
                        </Route>

                        {/* School */}
                        <Route
                          element={<SchoolRoot />}
                          path={routes.school.home}
                        >
                          <Route
                            element={<SchoolHome />}
                            path={routes.school.home}
                          />

                          {/* Jobs */}
                          <Route
                            element={<JobCreate />}
                            path={routes.school.job.create}
                          />
                          <Route
                            element={<JobCreate />}
                            path={routes.school.job.edit}
                          />
                          <Route
                            element={<JobDetails />}
                            path={routes.school.job.details}
                          />
                          <Route
                            element={<JobClosed />}
                            path={routes.school.job.closed}
                          />

                          {/* Profile */}
                          <Route
                            element={<Profile />}
                            path={routes.school.profile}
                          />
                          <Route
                            element={<EditProfile />}
                            path={routes.school.editProfile}
                          />

                          {/* Teacher */}
                          <Route
                            element={<Interview />}
                            path={routes.school.teacherInterview}
                          />
                          <Route
                            element={<TeacherTimeline />}
                            path={routes.school.teacherTimeline}
                          />
                          <Route
                            element={<ViewOffer />}
                            path={routes.school.viewOffer}
                          />
                        </Route>

                        {/* Account */}
                        <Route element={<Settings />} path={routes.settings} />

                        {/* Assessments */}
                        {/* <Route
                        element={<AssessmentForm />}
                        path={routes.assessment.create}
                      />
                      <Route
                        element={<AssessmentForm />}
                        path={routes.assessment.edit}
                      />
                      <Route
                        element={<AssessmentDetails />}
                        path={routes.assessment.details}
                      />
                      <Route
                        element={<AssessmentResult />}
                        path={routes.assessment.result}
                      /> */}
                        {/* <Route
                        element={<HiringTools />}
                        path={routes.assessments}
                      /> */}

                        <Route
                          element={<OfferLetterSent />}
                          path={routes.offerLetterSent}
                        />
                        <Route
                          element={<ErrorPage errorCode={404} />}
                          path={routes.pageNotFound}
                        />
                      </Route>
                    </Route>
                  </Routes>
                  {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                </GlobalState>
              </ToastProvider>
            </Maintenance>
          </ThemeProvider>
        </QueryClientProvider>
      </Router>
    </PostHogProvider>
  )
}

export default App
