import { Button } from "@suraasa/placebo-ui"
import { LiveDemoDetailsStep } from "api/resources/jobs/step.types"
import EditLiveDemoDialog from "components/teacher/Dialogs/EditLiveDemoDialog"
import React, { useContext, useState } from "react"
import { useParams } from "react-router-dom"
import useToggle from "utils/hooks/useToggle"
import TeacherTimelineContext from "views/teacher/context"

import Card from "./Card"

const LiveDemos = () => {
  const { liveDemos, actionsAllowed } = useContext(TeacherTimelineContext)
  const { jobApplicantId, schoolId } = useParams()

  const [openLiveDemo, toggleOpenLiveDemo] = useToggle(false)
  const [liveDemoToEdit, setLiveDemoToEdit] = useState<LiveDemoDetailsStep>()

  const handleLiveDemoToEdit = (LiveDemo: LiveDemoDetailsStep) => {
    setLiveDemoToEdit(LiveDemo)
    toggleOpenLiveDemo()
  }

  return (
    <>
      <EditLiveDemoDialog
        afterSubmit={item =>
          liveDemoToEdit
            ? liveDemos.update(liveDemoToEdit?.id, item)
            : liveDemos.add(item)
        }
        schoolId={schoolId || ""}
        data={liveDemoToEdit}
        jobApplicant={jobApplicantId}
        open={openLiveDemo}
        toggle={() => {
          toggleOpenLiveDemo()
          setLiveDemoToEdit(undefined)
        }}
      />

      <div className="flex flex-col gap-2">
        <Button
          className="ml-auto"
          disabled={!actionsAllowed}
          variant="outlined"
          onClick={() => toggleOpenLiveDemo()}
        >
          Add Video Interview
        </Button>
        {liveDemos.data.map(item => (
          <Card
            {...item}
            handleLiveDemoToEdit={() => handleLiveDemoToEdit(item)}
            key={item.id}
          />
        ))}
      </div>
    </>
  )
}

export default LiveDemos
