import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  InputLabel,
  Select,
  TextField,
  Toggle,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  JobApplicantStepStatus,
  LiveDemoDetailsStep,
  StepType,
} from "api/resources/jobs/step.types"
import RemoveDialog from "components/RemoveDialog"
import omit from "lodash/omit"
import React, { useContext, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { DURATION_HOURS, DURATION_MINUTES } from "utils/constants"
import { handleErrors, toDateTimeLocal } from "utils/helpers"
import useToggle, { ToggleValue } from "utils/hooks/useToggle"
import toast from "utils/toast"
import TeacherTimelineContext from "views/teacher/context"

type Props = {
  toggle: ToggleValue
  jobApplicant?: string | number
  afterSubmit?: (data: LiveDemoDetailsStep) => void
  data?: LiveDemoDetailsStep
  schoolId: string
} & Pick<DialogProps, "open">

type Form = {
  name: string
  scheduledTime: string
  topic: string
  instructions: string
  isVirtual: boolean
  durationMinutes: number
  durationHours: number
  meetingUrl: string | null
}

const EditLiveDemoDialog = ({
  open,
  data,
  toggle,
  afterSubmit,
  jobApplicant,
  schoolId,
}: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<Form>({
    defaultValues: {
      name: "",
      scheduledTime: "",
      topic: "",
      instructions: "",
      isVirtual: true,
      durationHours: 0,
      durationMinutes: 30,
      meetingUrl: "",
    },
  })

  const [openRemoveDialog, toggleRemoveDialog] = useToggle(false)
  const [removeLoading, toggleRemoveLoading] = useToggle(false)

  const isEditable = Boolean(data)
  const { liveDemos } = useContext(TeacherTimelineContext)
  const onSubmit = handleSubmit(async formData => {
    if (formData.durationHours === 0 && formData.durationMinutes === 0) {
      setError("durationMinutes", { message: "Duration too short" })
      return
    }

    if (isEditable && data) {
      const res = await api.teacher.liveDemo.update({
        data: {
          ...omit(formData, [
            "durationHours",
            "durationMinutes",
            "scheduledTime",
          ]),
          duration: formData.durationHours + formData.durationMinutes,
          scheduledTime: new Date(formData.scheduledTime).toISOString(),
          meetingUrl: formData.isVirtual ? formData.meetingUrl : undefined,
        },
        urlParams: {
          liveDemoId: data.step.id,
        },
        headers: {
          "School-Id": schoolId,
        },
      })

      if (res.isSuccessful) {
        toast.success("Video Interview updated successfully")

        if (afterSubmit) {
          const { dateCreated, ...apiData } = res.data
          afterSubmit({
            dueDate: res.data.scheduledTime,
            id: data.id,
            dateCreated,
            stepId: data.step.id,
            step: { ...data.step, ...apiData },
            stepType: StepType.LIVE_DEMO,
            status: data.status,
          })
        }
        toggle()
      } else {
        handleErrors(setError, omit(res.errors, "jobApplicant"))
      }
    } else {
      const res = await api.teacher.liveDemo.create({
        data: {
          duration: formData.durationHours + formData.durationMinutes,
          scheduledTime: new Date(formData.scheduledTime).toISOString(),
          ...omit(formData, [
            "durationHours",
            "durationMinutes",
            "scheduledTime",
          ]),
          jobApplicant: Number(jobApplicant),
          meetingUrl: formData.isVirtual ? formData.meetingUrl : undefined,
        },
        headers: {
          "School-Id": schoolId,
        },
      })

      if (res.isSuccessful) {
        if (afterSubmit) {
          const { dateCreated, jobApplicantStep, ...apiData } = res.data
          afterSubmit({
            dateCreated,
            dueDate: res.data.scheduledTime,
            id: jobApplicantStep,
            step: {
              ...apiData,
            },
            stepId: res.data.id,
            stepType: StepType.LIVE_DEMO,
            status: JobApplicantStepStatus.NOT_STARTED,
          })
          toast.success("Video Interview added successfully")
        }
        toggle()
      } else {
        toast.error(res.errors.message ?? "Something went wrong")
        handleErrors(setError, omit(res.errors, "jobApplicant"))
      }
    }
  })

  const isOnlineClass = watch("isVirtual")

  useEffect(() => {
    if (data?.step) {
      const durationMinutes = data.step.duration % 60
      const durationHours = data.step.duration - durationMinutes
      reset({
        ...data.step,
        topic: data.step.topic || undefined,
        scheduledTime: toDateTimeLocal(data.step.scheduledTime),
        durationHours,
        durationMinutes,
      })
    }
  }, [data, reset])

  const handleRemove = async () => {
    if (!data?.id) return
    toggleRemoveLoading(true)
    const res = await api.teacher.jobApplicantStep.delete({
      urlParams: {
        stepId: data.id,
      },
      headers: {
        "School-Id": schoolId,
      },
    })

    if (res.isSuccessful) {
      liveDemos.remove(data.id)
      toggleRemoveDialog()
      toggle()
      toast.success("Live demo cancelled successfully")
    } else {
      toast.error(res.errors.message || "Live demo couldn't be cancelled")
    }
    toggleRemoveLoading(false)
  }

  return (
    <>
      <Dialog
        open={open}
        shouldCloseOnOverlayClick={false}
        width="md"
        onAfterClose={() => reset({})}
        onRequestClose={() => toggle()}
      >
        <DialogTitle>
          {isEditable ? "Update" : "Add"} Video Interview
        </DialogTitle>

        <DialogContent>
          <form className="flex flex-col gap-2" onSubmit={onSubmit}>
            <TextField
              {...register("name", {
                required: { value: true, message: "Required" },
              })}
              disabled={isEditable}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
              label="name"
              placeholder="Ex: Mathematics Live Class"
              fullWidth
            />

            <TextField
              type="datetime-local"
              {...register("scheduledTime", {
                required: { value: true, message: "Required" },
              })}
              error={Boolean(errors.scheduledTime)}
              helperText={errors.scheduledTime?.message}
              label="date & time"
              min={toDateTimeLocal(new Date().toISOString(), {
                omitSeconds: true,
              })}
              fullWidth
            />

            <div>
              <InputLabel label="duration" />
              <div className="flex flex-wrap gap-2 sm:flex-nowrap">
                <Controller
                  control={control}
                  name="durationHours"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      error={Boolean(errors.durationHours)}
                      helperText={errors.durationHours?.message}
                      options={DURATION_HOURS}
                      placeholder="0 hours"
                      value={DURATION_HOURS.find(c => c.value === value)}
                      fullWidth
                      onBlur={onBlur}
                      onChange={val => onChange(val?.value)}
                    />
                  )}
                  rules={{ required: { value: true, message: "Required" } }}
                />

                <Controller
                  control={control}
                  name="durationMinutes"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      error={Boolean(errors.durationMinutes)}
                      helperText={errors.durationMinutes?.message}
                      options={DURATION_MINUTES}
                      placeholder="15 minutes"
                      value={DURATION_MINUTES.find(c => c.value === value)}
                      fullWidth
                      onBlur={onBlur}
                      onChange={val => onChange(val?.value)}
                    />
                  )}
                  rules={{ required: { value: true, message: "Required" } }}
                />
              </div>
            </div>

            <TextField
              {...register("topic", {
                required: { value: true, message: "Required" },
              })}
              error={Boolean(errors.topic)}
              helperText={errors.topic?.message}
              label="Topic"
              placeholder="Ex: Mathematics"
              fullWidth
            />

            {/* @ts-expect-error placebo-issue */}
            <TextField
              {...register("instructions", {
                required: { value: true, message: "Required" },
                maxLength: { value: 1500, message: "Maximum 1500 characters" },
              })}
              error={Boolean(errors.instructions)}
              helperText={errors.instructions?.message}
              label="Instructions"
              placeholder="Any instruction you would like to give"
              rows={3}
              fullWidth
              multiLine
            />

            <Toggle
              {...register("isVirtual")}
              label="This is an online interview"
            />

            <TextField
              disabled={!isOnlineClass}
              {...register("meetingUrl", {
                required: { value: isOnlineClass, message: "Required" },
              })}
              error={Boolean(errors.meetingUrl)}
              helperText={errors.meetingUrl?.message}
              label="Meeting link"
              placeholder="Zoom / Google Meet / Microsoft Teams link"
              fullWidth
            />
          </form>
        </DialogContent>

        <DialogFooter
          actions={{
            tertiary: isEditable
              ? {
                  label: "Cancel Video Interview",
                  color: "critical",
                  variant: "text",
                  onClick: () => toggleRemoveDialog(),
                }
              : null,
            primary: {
              label: isEditable ? "Update" : "Schedule Video Interview",
              onClick: onSubmit,
              loading: isSubmitting,
            },
          }}
        />
      </Dialog>

      <RemoveDialog
        handleClose={toggleRemoveDialog}
        loading={removeLoading}
        open={openRemoveDialog}
        title="Cancel Video Interview"
        keepScrollLocked
        onRemove={handleRemove}
      >
        Are you sure you want to cancel this Video Interview? This action can’t
        be reversed.
      </RemoveDialog>
    </>
  )
}

export default EditLiveDemoDialog
